#bg {
  opacity: 0.76;
}

p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: rgba(18, 18, 18, 0.7);
}

h5 {
  color: rgba(18, 18, 18, 0.5);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.display-title {
  color: #fff;
  font-family: "Poppins";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.display-digit {
  color: #fff;
  font-family: "Poppins";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* .wallet{
 position: absolute;
width: 230px;
height: 107px;
left: 300px;
top: 163px;
mix-blend-mode: overlay;
opacity: 0.76;
background-color: #042f9c;;
mix-blend-mode: overlay;
opacity: 0.76;
}

.icon-acct{
    position: absolute;
    top: 173px;
    width: 30px;
    height: 30px;
    left: 320px;
    color: #FFFFFF;
}

.visitor {
    position: absolute;
    left: 358px;
    top: 180px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
   line-height: 22px;
    color: #FFFFFF;

}

.money{
position: absolute;
width: 84px;
height: 46px;
left: 370px;
top: 208px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 48px;
color: #FFFFFF;
}


.total-orders{
    position: absolute;
    left: 580px;
    top: 180px;
    color: #042f9c;
}

.orders{
    position: absolute;
    left: 600px;
    top: 175px;
    font-family: 'Poppins';
   font-style: normal; 
   font-weight: 500;
   font-size: 15px; 
   line-height: 22px;
   color: #042f9c;

}

.total-amount{
position: absolute;
width: 84px;
height: 46px;
left: 600px;
top: 195px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 48px;
color: #042f9c;
}

.pending-orders{
 position: absolute;
width: 230px;
height: 107px;
left: 730px;
top: 163px;
background-color: orange;
mix-blend-mode: overlay;
opacity: 0.76;
}

.pending-visitor{
    position: absolute;
    top: 173px;
    width: 30px;
    height: 30px;
    left: 750px;
    color: #FFFFFF;
}

.total-prnding{
    position: absolute;
width: 159px;
height: 23px;
left: 784px;
top: 184px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 22px;
color: #FFFFFF;
}

.total_pending-amount{
    position: absolute;
width: 84px;
height: 46px;
left: 800px;
top: 208px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 48px;
color: #FFFFFF;

}

.total_successful-orders{
position: absolute;
width: 169px;
height: 23px;
left: 960px;
top: 180px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 22px;
color: #042f9c;
}


.successful-orders{
    position: absolute;
    width: 169px;
    height: 23px;
    left: 1060px;
    top: 181px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #042f9c;
    
}

.successful-amount{
position: absolute;
width: 80px;
height: 48px;
left: 1059px;
top: 208px;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 32px;
line-height: 48px;
color: #042f9c;

}

.Recent-order{
position: absolute;
width: 157px;
height: 36px;
left: 300px;
top: 330px;
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
color: #042f9c
}

.customer-table{
position: relative;
top: 450px;
left: 380px;
font-family: 'Raleway';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 140%;
color: #042f9c;
}

.displaying{
font-family: 'Raleway';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 140%;
letter-spacing: 0.02em;
color: rgba(18, 18, 18, 0.4);
}

.search-order{
box-sizing: border-box;
position: absolute;
width: 400px;
height: 38px;
left: 380px;
top: 2px;
background: rgba(252, 254, 255, 0.7);
border: 0.3px solid rgba(18, 18, 18, 0.6);
border-radius: 6px;
color: #000;
}

button{
position: absolute;
left: 720px;
right: 65px;
top: 2px;
background-color: #042f9c;
border-radius: 6px;
width: 90px;
height: 38px;
color: #FFFFFF;
}




#table{
    margin-top: 500px;
    margin-left: 280px;
    color: rgba(18, 18, 18, 0.4);
}

.HiOutlineTrash{
    width: 25px;
    height: 25px;
    left: 457px;
    border-radius: 50px;
    background-color: gray;
    cursor: pointer;
}

.completed{
    color: #71DD37;
} */
