/* src/Marquee.css */
.marquee-container {
  width: 100%;
  overflow: hidden;
  background: #000;
  color: #fff;
  white-space: nowrap;
}

.marquee {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 25s linear infinite;
}

.marquee span {
  display: inline-block;
  padding-right: 100%;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-50%);
  }
}

.animate-marquee {
  animation: marquee 40s linear infinite;
}
