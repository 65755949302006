.input-border {
  border: 1px solid #042f9c;
  border-left: 6px solid #042f9c;
}

.error-border {
  border: 1px solid #ec0d63;
  border-left: 6px solid #ec0d63;
}

.wallet-bg {
  background-image: url("../../assets/svg/illustration.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 30rem;
}

.crypto-bg {
  background-image: url("../../assets/svg/removebg-preview.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 30rem;
}

.pa-bg {
  background-image: url("../../assets/svg/pa-agent.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 30rem;
}

.giftcard-bg {
  background-image: url("../../assets/svg/man-and-rocket-2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 30rem;
}

.bg-ellipse {
  background-image: url("../../assets/icons/Ellipse.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: inherit;
}

.about-bg {
  background-image: url("../../assets/images/three-coporate.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: inherit;
}

.phone-vector-bg {
  border-radius: 5px;
  background: linear-gradient(
    178deg,
    #fde4e0 -10.98%,
    rgba(240, 168, 168, 0) 97.98%
  );
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.05);
}

.giftcard-vector-bg {
  border-radius: 5px;
  background: linear-gradient(
    181deg,
    #d9efff 40.86%,
    rgba(255, 255, 255, 0) 99.9%
  );
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.08);
}

.airtime-vector-bg {
  border-radius: 5px;
  background: linear-gradient(
    172deg,
    rgba(134, 82, 164, 0) 12.95%,
    rgba(134, 82, 164, 0.6) 143.44%
  );
  box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.08);
}

.vector-bg1 {
  background-image: url("../../assets/icons/vector1.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: inherit;
  margin-top: 3em;
  padding-bottom: 3.5em;
}

.vector-bg2 {
  background-image: url("../../assets/icons/vector2.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: inherit;
  margin-top: 3em;
  padding-bottom: 3.5em;
}
.about-bg2 {
  background-blend-mode: color;
  background-image: url("../../assets/images/team-members.png");
  background-color: rgba(0, 0, 0, 0.705);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  min-height: 400px;
}
.coin {
  position: absolute;
  margin-left: 28rem;
}

@media screen and(max-width: 600px) {
  .small-icon {
    /* height: 80px; */
    width: 6px;
  }
}
.small-icon {
  /* height: 80px; */
  width: 11rem;
}
.social-box {
  color: rgba(104, 37, 104, 0.87);
  background-color: white;
}
.social-box-hover {
  background-color: rgba(104, 37, 104, 0.87);
  color: white !important;
}
.social-box-text {
  color: rgb(49, 49, 49);
}
.social-box-hover-text {
  color: rgb(253, 253, 253);
}
.input-border {
  border: 1px solid #042f9c;
  border-left: 6px solid #042f9c;
}
.button {
  cursor: pointer;
  background-color: #042f9c;
  color: white;
  padding: 0.5rem 2rem;
}

@media screen and (max-width: 760px) {
  .small-screen-col {
    flex-direction: column;
    justify-content: center;
    margin-left: 35%;
    margin-right: auto;
  }
}

.table {
  width: 70vw;
  text-align: left !important;
}
.light-purple {
  background: #042f9c40;
}
